import React, { Component } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import logo from '../images/logo.svg';
// import '../style/elements/_nav.scss'

const NavigationMenu = styled.nav`
	text-align: right;
	z-index: 5;
	@media (max-width: 1024px) {
		opacity: 0;
		position: absolute;
		top: -350px;
		right: 0;
		// background-color: var(--brand-black);
		background-color: white;
		padding: 15px 0;
		transition: var(--transition);
		&.menu_active_true {
			display: block;
			opacity: 1;
			top: 71px;
			right: 0;
			transition: var(--transition);
		}
		ul {
			li {
				display: block;
				width: 100%;
				text-align: center;
				padding: 15px 0;
				a {
					padding: 7px 0;
					display: block;
				}
			}
		}
	}
	&.menu_active_true {
		@media (max-width: 1024px) {
			display: block;
			border: 1px solid var(--brand-gold);
		}
	}
	ul {
		display: block;
		display: grid;
		grid-template-columns: 1fr auto auto;
		justify-items: center;
		align-items: center;
		// float: right;
		padding: 0;
		margin: 0;
		@media (max-width: 1024px) {
			display: block;
			padding: 32px;
		}
		.socials {
			a {
				font-size: 16px;
			}
		}

		.socials,
		.languages,
		.pages {
			@media (max-width: 1024px) {
				text-align: center;
				li {
					display: inline-block;
					padding: 12px 0;
					display: inline-block;
					width: auto;
					margin-right: 10px;
					float: none;
					clear: both;
					text-align: center;
				}
			}
		}
		.pages {
			@media (max-width: 1024px) {
				padding-bottom: 40px;
				li {
					display: block;
					margin-right: 0;
				}
			}
		}
		li {
			display: block;
			float: left;
			padding: 0 10px;
			text-transform: uppercase;
			text-decoration: none;
			position: relative;
			transition: var(--transition);
			color: var(--brand-black);
			// color: var(--brand-gold);
			cursor: pointer;
			@media (max-width: 1024px) {
				padding: 12px 0;
			}
			&:hover {
				transition: var(--transition);
				a {
					// color: white;
					color: var(--brand-gold);
					transition: var(--transition);
				}
				&:after {
					// color: white;
					color: var(--brand-black);
					text-shadow: 7px 0 #c1ad7b, -7px 0 #c1ad7b, 0px 0 #c1ad7b;
				}
			}
			&:after {
				position: absolute;
				top: 70%;
				left: 50%;
				color: transparent;
				content: '•';
				text-shadow: 0 0 transparent;
				font-size: 12px;
				transition: text-shadow 0.3s, color 0.3s;
				transform: translateX(-50%);
				color: transparent;
				text-shadow: 7px 0 transparent, -7px 0 transparent, 0 0 transparent;
				pointer-events: none;
			}
			a {
				display: block;
				text-transform: uppercase;
				text-decoration: none;
				position: relative;
				transition: var(--transition);
				color: var(--brand-black);
				@media (max-width: 1024px) {
					padding: 0;
				}
				/* &:after {
				position: absolute;
				top: 70%;
				left: 50%;
				color: transparent;
				content: '•';
				text-shadow: 0 0 transparent;
				font-size: 12px;
				transition: text-shadow 0.3s, color 0.3s;
				transform: translateX(-50%);
				color: transparent;
				text-shadow: 7px 0 transparent, -7px 0 transparent,
					0 0 transparent;
				pointer-events: none;
			}
			&:hover {
				color: var(--brand-gold);
				// color: white;
				transition: var(--transition);
				&:after {
					color: var(--brand-gold);
					// color: white;
					text-shadow: 7px 0 #c1ad7b, -7px 0 #c1ad7b,
						0px 0 #c1ad7b;
				}
			} */
			}
		}
	}
`;

const HeaderWrap = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	// background-color: var(--brand-black);
	background-color: white;
	.container {
		width: 100%;
		transition: all 0.3s ease;
		padding: 15px 30px;
	}
	&.fixed {
		// background-color: var(--brand-black);
		background-color: white;
		box-shadow: 0px -4px 34px -1px rgba(0, 0, 0, 0.57);

		.container {
			width: 100%;
			transition: all 0.3s ease;
			padding: 15px 30px;
		}
	}
	&.menu_active_true {
		// background-color: var(--brand-black);
		background-color: white;
		.container {
			.toggle_menu {
				border: 1px solid var(--brand-gold);
			}
		}
	}
	.container {
		display: grid;
		// grid-template-columns: auto 1fr;
		grid-template-columns: minmax(200px, auto) 1fr;
		align-items: center;
		transition: all 0.3s ease;
		box-sizing: border-box;
		// padding: 28px 0;
		padding: 28px 30px;
		@media (max-width: 1024px) {
			padding: 20px 15px;
			&.menu_active_true {
				// background-color: var(--brand-black);
				background-color: white;
				nav.main_menu {
					display: block;
				}
			}
		}
		.logo {
			a {
				display: block;
				img {
					display: block;
					height: 40px;
					width: auto;
				}
			}
		}
		.toggle_menu {
			display: none;
			position: absolute;
			right: 15px;
			border: 1px solid var(--brand-black);
			// background-color: var(--brand-black);
			background-color: white;
			padding: 6px 12px;
			outline: 0;
			svg {
				font-size: 32px;
				color: var(--brand-gold);
			}
			@media (max-width: 1024px) {
				display: block;
			}
		}
	}
`;

class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mobileMenuActive: false,
		};

		this.handleScroll = this.handleScroll.bind(this);
		this.handleMobileMenu = this.handleMobileMenu.bind(this);
		this.scrollToTop = this.scrollToTop.bind(this);
	}

	componentDidMount() {
		// fixed nav
		const el = document.querySelector('nav');
		this.setState({ top: el.offsetTop, height: el.offsetHeight });
		window.addEventListener('scroll', this.handleScroll);

		// smooth scroll
		if (typeof window !== 'undefined') {
			// eslint-disable-next-line global-require
			require('smooth-scroll')('a[href*="#"]');
		}

		// scroll to element on load if hash in url exists
		if (window.location.hash) {
			let { hash } = window.location;
			hash = hash.replace('#', '');
			const section = document.getElementById(hash);
			const sectionFromTop = section.offsetTop - 250;

			section.scrollTo({
				top: sectionFromTop,
				left: 0,
				behavior: 'smooth',
			});
		}

		this.setState({
			mobileMenuActive: false,
		});
	}

	// eslint-disable-next-line class-methods-use-this
	scrollToElement = (e) => {
		const elementTo = e.currentTarget.dataset.scroll;
		const scrollToSectionID = document.getElementById(elementTo);
		const sectionFromTop = scrollToSectionID.offsetTop - 250;

		window.scrollTo({
			top: sectionFromTop,
			left: 0,
			behavior: 'smooth',
		});
	};

	handleScroll() {
		this.setState({
			scroll: window.scrollY - 250,
		});
	}

	handleMobileMenu() {
		this.setState({
			mobileMenuActive: !this.state.mobileMenuActive,
		});
	}

	// eslint-disable-next-line class-methods-use-this
	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	render() {
		const classFixed = this.state.scroll > this.state.top ? 'fixed' : '';

		const buttonScroll = this.state.scroll > 300 ? 'is_active' : '';
		return (
			<>
				<HeaderWrap className={`${classFixed} menu_active_${this.state.mobileMenuActive}`}>
					<div className={`container menu_active_${this.state.mobileMenuActive}`}>
						<div className="logo">
							<Link to="/" rel="prev">
								<img src={logo} alt="Eichholtz Logo" />
							</Link>
						</div>
						<NavigationMenu className={`menu_active_${this.state.mobileMenuActive}`}>
							<ul onClick={this.handleMobileMenu}>
								<div className="pages">
									<li>
										<Link to="/" rel="prev">
											Home
										</Link>
									</li>
									<li>
										<Link to="/o-nama" rel="prev">
											About us
										</Link>
									</li>
									<li>
										<Link to="/kontakt" rel="prev">
											Contact
										</Link>
									</li>
								</div>
								<div className="languages">
									{/* <li>
										<Link to="/" rel="prev">
											RS
										</Link>
									</li>
									<li>
										<Link to="/en" rel="prev">
											EN
										</Link>
									</li> */}
								</div>
								<div className="socials">
									<li>
										<a href="mailto:office@trendenterijeri.rs" target="_blank" rel="noreferrer">
											<FontAwesomeIcon icon={faEnvelope} />
										</a>
									</li>
									<li>
										<a
											href="https://www.facebook.com/eichholtzBV/"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faFacebookF} />
										</a>
									</li>
									<li>
										<a
											href="https://www.linkedin.com/company/eichholtz/"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faLinkedinIn} />
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/worldofeichholtz/"
											target="_blank"
											rel="noreferrer"
										>
											<FontAwesomeIcon icon={faInstagram} />
										</a>
									</li>
								</div>
							</ul>
						</NavigationMenu>

						<button type="button" className="toggle_menu" onClick={this.handleMobileMenu}>
							<FontAwesomeIcon icon={faBars} />
						</button>
					</div>
				</HeaderWrap>
				<button
					onClick={this.scrollToTop}
					className={`back_to_top ${buttonScroll}`}
					type="button"
					name="Back to top"
				>
					<span />
				</button>
			</>
		);
	}
}

export default Navigation;
