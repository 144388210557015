import React, { Component } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//     faFacebookF,
//     faInstagram,
//     faPinterestP,
// } from '@fortawesome/free-brands-svg-icons'

class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="container">
					<div className="copyrights">
						Designed & Developed by: <a href="https://singularminds.eu">@singularminds.eu </a>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer
